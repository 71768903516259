<template>
  <ul class="timeline-wrapper" @scroll="scrollEvent">
    <li
      class="timeline-item"
      v-for="(item, idx) in timelineList"
      :key="item.id"
    >
      <div class="timeline-box">
        <div
          class="out-circle"
          :style="
            item.id == 6
              ? 'background:rgba(0,0,0,0);border:none;box-shadow:none'
              : ''
          "
        >
          <!-- <div  :class="` ${item.isbotm  ? 'box_line' : 'box_line2'}`">
            
            </div>
            <div :class="` ${
                   item.isbotm ? 'box_text' : 'box_text2'
                  }`">
                    {{item.date}}
                <p>“易橙学堂”品牌诞生</p>
            </div> -->
          <!-- <div class="in-circle"></div> -->
          <!-- <div class="timeline-date">
              <el-popover
                placement="bottom"
                title="标题"
                width="200"
                trigger="hover"
                :content="item.content"
              >
                <el-button type="text" slot="reference" class="father-text">{{
                  item.date
                }}</el-button>
              </el-popover>
            </div> -->
        </div>
        <div
          class="long-line"
          v-show="item.isShow"
          :style="`width:${
            item.children ? (item.children.length + 1) * 70 : 1 * 70
          }px`"
        >
          <div
            :class="` ${item.isbotm ? 'box_line' : 'box_line2'}`"
            v-if="item.id != 6"
          ></div>
          <div
            :class="` ${item.isbotm ? 'box_text' : 'box_text2'}`"
            v-if="item.id != 6"
          >
            {{ item.date }}
            <p>{{ item.content }}</p>
          </div>

          <!-- <div
              v-for="(subItem, index) in item.children"
              :key="subItem.id"
              class="sub-item-box"
            >
              <span>{{ subItem.name + ":" + subItem.num }}人</span>
              根据奇数偶数来判断向上还是向下
              <div
                :class="`sub-line-box ${
                  index % 2 == 0 ? 'top-line-box' : 'bottom-line-box'
                }`"
                v-show="subItem.content"
              >
                <div
                  :class="`children-line-box ${
                    index % 2 == 0 ? 'top-line' : 'bottom-line'
                  }`"
                ></div>
                <div
                  :class="`children-box ${
                    index % 2 == 0 ? 'top-children-box' : 'bottom-children-box'
                  }`"
                >
                  {{ subItem.content }}
                </div>
              </div>
            </div> -->
        </div>
      </div>
    </li>
  </ul>
</template>
  
<script>
import Vue from "vue";
export default Vue.component("Timeline", {
  name: "Timeline",
  props: {
    timelineList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {},
  methods: {
    scrollEvent(e) {
      this.$emit("scrollEvent", e);
    },
    handleBottomClick() {
      this.$emit("handleBottomClick");
    },
  },
});
</script>
  
<style scoped lang="scss">
.box_line {
  width: 1px;
  height: 100px;
  // background: red;
  border-left: 2px dashed #1faa78;
  margin-top: -90px;
  transform: rotate(45deg);
  margin-left: 30px;
  position: relative;
}

.box_line2 {
  width: 1px;
  height: 100px;
  // background: red;
  border-left: 2px dashed #1faa78;
  // margin-top: 90px;
  transform: rotate(45deg);
  margin-left: -50px;
  position: relative;
}

.box_text2 {
  width: 200px;
  margin-top: 100px;
  margin-left: -190px;
  text-align: right;
  font-size: 20px;
  color: #121212;
  font-weight: bold;

  > p {
    width: 200px;
    line-height: 17px;
    font-weight: normal;

    overflow: hidden;
    font-size: 12px;
    color: #545454;
    margin-top: 6px;
  }
}

.box_text {
  width: 200px;
  font-size: 20px;
  color: #121212;
  font-weight: bold;
  text-align: left;

  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: -120px;
  margin-left: 100px;

  > p {
    width: 200px;
    line-height: 17px;
    font-weight: normal;

    overflow: hidden;
    font-size: 12px;
    color: #545454;
    margin-top: 6px;
  }
}

ul.timeline-wrapper {
  list-style: none;
  margin: 0;
  padding: 0;
  padding: 200px 20px;
  margin: 0 auto;
  // white-space: nowrap;
  // overflow-x: scroll;
}

/* 时间线 */
.timeline-item {
  position: relative;
  display: inline-block;

  .timeline-box {
    text-align: center;

    // position: absolute;
    display: flex;
    align-items: center;

    .out-circle {
      width: 16px;
      height: 16px;
      background: #ff6000;
      border: 3px #fff solid;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.4);
      /*opacity: 0.1;*/
      border-radius: 50%;
      display: flex;
      align-items: center;
      cursor: pointer;

      .in-circle {
        width: 8px;
        height: 8px;
        margin: 0 auto;
        background: rgba(14, 116, 218, 1);
        border-radius: 50%;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
      }

      .timeline-date {
        color: #333;
        margin-top: 40px;

        .father-text {
          font-weight: 900;
          font-size: 16px;
          margin-left: -15px;
        }
      }
    }

    .long-line {
      // width: 300px;
      height: 4px;
      background: #1faa78;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.3);
      display: flex;

      // flex-direction: revert;
      // justify-content: space-around;
      .sub-item-box {
        margin-top: -20px;
        position: relative;

        .sub-line-box {
          // cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .children-line-box {
            width: 0px;
            border-left: 1px solid rgba(14, 116, 218, 0.3);
          }

          .children-box {
            flex-wrap: wrap;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(14, 116, 218, 0.3);
            white-space: break-spaces;
            text-align: center;
            padding: 5px;
          }
        }

        .top-line-box {
          margin-top: -100px;
          height: 60px;
        }

        .bottom-line-box {
          margin-top: 5px;
          height: 150px;
        }

        .top-line {
          height: 65px;
        }

        .bottom-line {
          height: 120px;
        }

        .top-children-box {
          margin-top: -90px;
          // height: 30px;
          width: 100px;
        }

        .bottom-children-box {
          // height: 120px;
          width: 150px;
        }
      }
    }
  }

  .timeline-content {
    box-sizing: border-box;
    margin-left: 20px;
    height: 106px;
    padding: 0 0 0 20px;
    text-align: left;
    margin-bottom: 30px;

    .timeline-title {
      font-size: 14px;
      word-break: break-all;
      margin-bottom: 16px;
      color: #333;
      font-weight: 500;
      /*display: inline;*/
    }

    .timeline-desc {
      font-size: 14px;
      color: #999999;
    }
  }
}

.timeline-item:last-of-type .timeline-content {
  margin-bottom: 0;
}
</style>