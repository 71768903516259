<template>
  <div class="app-container">
    <div class="banner">
      <img src="@/assets/img/about_bg.jpg" alt="" />
    </div>
    <div class="about">
      <div class="about_min">
        <div class="about_left">
          <div class="about_title textdong animate__fadeInDown">
            关于易橙<span> / ABOUT YICHENG</span>
          </div>
          <p class="textdong animate__bounceInLeft">
            易橙学堂是博财汇德信息技术(北京)有限公司旗下教育品牌，博财汇德是一家实力强劲的公司，它不仅获得了由北塔资本、维创资本、深度加速、大搜股的千万级投资，还屡次荣获包括：影响力在线教育品牌、行业标杆集团、公众信赖的技能培训品牌等荣誉。
            易橙学堂是在线教育行业的领跑者，多年来坚持初心做良心企业，打造一站式课堂，专注于军队文职教育等领域，所有课程产品均由行业资深专家亲自主操研发，历经数年教学经验打磨，对答题技巧和面试技巧，也是有自己独特的方式和见解，受到业内的好评！
          </p>
        </div>
        <div class="about_right">
          <div>
            <img src="@/assets/img/about_top.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="history">
      <div class="history_min">
        <div class="about_title textdong animate__fadeInDown">
          发展历程<span> / DEVELOPMENT HISTORY</span>
        </div>
        <div class="item">
          <Timeline :timelineList="timeLineArr" @scrollEvent="scrollEvent" />
        </div>
      </div>
    </div>
    <div class="history" style="background-color: #fff">
      <div class="history_min">
        <div class="about_title textdong animate__fadeInDown">
          联系我们<span> / CONTACT US </span>
        </div>
        <div class="item">
          <div class="item_left" @click="ongtoaddress">
            <img src="@/assets/img/address.jpg" alt="" />
          </div>
          <div class="item_right">
            <p>博财汇德信息技术（北京）有限公司</p>
            <p style="font-size: 16px">
              公司地址：北京市朝阳区农展南路5号9层901内-23
            </p>
            <p style="font-size: 16px">公司邮编：100020-23</p>
          </div>
        </div>
      </div>
    </div>

    <div class="contact">
      <div class="contact_min"></div>
    </div>
  </div>
</template>
  
<script>
import Wow from "wow.js";
import Timeline from "@/components/Timeline/index";
export default {
  name: "About",
  components: {
    Timeline,
  },
  data() {
    return {
      nomore: false,
      // 初始话模拟数据，数据较多时即可，形成滚动条。
      timeLineArr: [
        {
          id: 6,
          date: "",
          title: "2015",
          content: "公司成立，开展线下培训业务",
          isShow: true,
          isbotm: true,
          children: [{}],
        },
        {
          id: 1,
          date: "2017 年 09 月 ",
          title: "2015",
          content: "公司成立，开展线下培训业务",
          isShow: true,
          isbotm: true,
          children: [{}],
        },
        {
          id: 2,
          date: "2020 年2月",
          title: "2016",
          content: "受到疫情的影响开展线上培训业务",
          isShow: true,
          isbotm: false,
          children: [
            {
              name: "创始团队",
              num: 5,
              content: "前期公司规划",
            },
          ],
        },
        {
          id: 3,
          date: "2020年12月",
          title: "2017",
          content:
            "荣获腾讯教育颁发的“2020 年度影响力在线教育品牌荣获中国好教育颁发的“2020 年度公众信赖技能培训品牌",
          isShow: true,
          isbotm: true,
          children: [
            {
              name: "销售部",
              num: 10,
              content: "负责市场开发",
            },
            {
              name: "技术部",
              num: 20,
              content: "前端：5人，后端10人，测试5人",
            },
          ],
        },
        {
          id: 4,
          date: "2021年12月",
          title: "2018",
          content:
            "2021 年度行业标杆集团荣获腾讯教育颁发的“2021 年度影响力在线教育品牌",
          isbotm: false,

          isShow: true,
          children: [
            {
              name: "人力资源部",
              num: 3,
              content: "负责人才招聘",
            },
          ],
        },
        {
          id: 5,
          date: "2023年1月",
          title: "2019",
          content: "荣获腾讯教育颁发的“2022 年度影响力在线教育品牌”",
          isbotm: true,

          isShow: true,
          children: [
            {
              name: "商务企划部",
              num: 2,
              content: "对外合作",
            },
          ],
        },
      ],
      imgurl: "https://image.xicaishe.com/1678952050220_RN8dKH.jpg",
    };
  },
  methods: {
    // 滚动监听
    scrollEvent(e) {
      return;
      if (
        e.srcElement.scrollLeft + e.srcElement.clientWidth >=
        e.srcElement.scrollWidth
      ) {
        console.log("嘿嘿我在底部触发了");
        // 这里正常请求数据即可
        let data = [
          {
            id: 12,
            date: "2020",
            title: "2020",
            content: "2020年，受全球疫情影响，公司暂未扩招人员",
            isShow: true,
            children: [],
          },
          {
            id: 22,
            date: "2021",
            title: "2021",
            content: "公司被xxx投资公司注入资本1000万，公司天使轮融资成功",
            isShow: true,
            children: [
              {
                name: "仓储部",
                num: 30,
                content: "负责货物存储",
              },
              {
                name: "物流部",
                num: 40,
                content: "负责自有配送",
              },
            ],
          },
          {
            id: 23,
            date: "2022",
            title: "2022",
            content: "公司进入A轮融资，公司被xx投资公司注入资本8000万。",
            isShow: false,
            children: [],
          },
        ];
        if (!this.nomore) {
          this.timeLineArr[this.timeLineArr.length - 1].isShow = true;
          this.timeLineArr.push(...data);
          this.nomore = true;
        }
      }
    },
    ongtoaddress() {
      window.open(
        "https://map.baidu.com/search/%E4%BA%AC%E6%9C%9D%E5%A4%A7%E5%8E%A6/@12966202.070888195,4829549.23636925,19z?querytype=s&da_src=shareurl&wd=%E4%BA%AC%E6%9C%9D%E5%A4%A7%E5%8E%A6&c=131&src=0&wd2=%E5%8C%97%E4%BA%AC%E5%B8%82%E6%9C%9D%E9%98%B3%E5%8C%BA&pn=0&sug=1&l=13&b=(12925994,4825090;12972074,4847554)&from=webmap&biz_forward=%7B%22scaler%22:2,%22styles%22:%22pl%22%7D&sug_forward=442076468df0b96bdfee8b8f&device_ratio=2"
      );
    },
  },
  mounted() {
    const wow = new Wow({
      // 对所有拥有该class的元素挂载动画
      boxClass: "textdong",
      // 需要挂载的动画元素
      animateClass: "animate__animated",
      // 偏移值（即偏移一定距离后再执行动画，推荐设置！）
      offset: 100,
      // 移动设备是否生效
      mobile: true,
      // 是否支持异步
      live: true,
    });
    wow.init();
  },
};
</script>
<style lang="scss" scoped>
.banner {
  min-width: 1200px;
  width: 100%;
  > img {
    width: 100%;
    border: none;
  }
}
.about {
  width: 100%;
  min-width: 1200px;
  padding-bottom: 80px;
  background: white;
  overflow: hidden;
  .about_min {
    width: 70%;
    min-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .about_left {
      width: 58%;
      > p {
        font-size: 16px;
        line-height: 40px;
        color: rgba(131, 131, 131, 1);
      }
      > p:nth-of-type(2) {
        color: #545454;
        font-weight: bold;
      }
    }
    .about_right {
      width: 40%;
      display: flex;
      align-items: center;
      height: 360px;
      padding-top: 90px;
      > img {
        width: 360px;
        float: right;
        margin-top: 20px;
        display: block;
      }
    }
  }
}
.about_title {
  font-size: 40px;
  font-weight: bold;
  padding-top: 60px;
  padding-bottom: 40px;
  > span {
    font-size: 14px;
    color: #262626;
  }
}

.history {
  width: 100%;
  min-width: 1200px;
  overflow: hidden;
  padding-bottom: 80px;
  background: #f9f9f9;
  .history_min {
    width: 70%;
    min-width: 1200px;
    margin: 0 auto;
    .item {
      min-width: 1200px;
      display: flex;
      margin: 0 auto;
    }
    .item_left {
      cursor: pointer;
    }
    .item_right {
      margin-left: 100px;
      padding-top: 40px;
      > p:nth-of-type(1) {
        font-size: 24px;
        font-weight: bold;
        line-height: 40px;
      }
      > p:nth-of-type(2) {
        color: #262626;
        line-height: 40px;
      }
      > p:nth-of-type(3) {
        color: #262626;
        line-height: 40px;
        font-size: 16px;
      }
    }
  }
}
.contact {
  width: 100%;
  padding-bottom: 80px;
  background: white;
  > .contact_min {
    width: 70%;
    min-width: 1200px;
    margin: 0 auto;
  }
}
</style>
  