var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"history"},[_c('div',{staticClass:"history_min"},[_vm._m(2),_c('div',{staticClass:"item"},[_c('Timeline',{attrs:{"timelineList":_vm.timeLineArr},on:{"scrollEvent":_vm.scrollEvent}})],1)])]),_c('div',{staticClass:"history",staticStyle:{"background-color":"#fff"}},[_c('div',{staticClass:"history_min"},[_vm._m(3),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item_left",on:{"click":_vm.ongtoaddress}},[_c('img',{attrs:{"src":require("@/assets/img/address.jpg"),"alt":""}})]),_vm._m(4)])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("@/assets/img/about_bg.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('div',{staticClass:"about_min"},[_c('div',{staticClass:"about_left"},[_c('div',{staticClass:"about_title textdong animate__fadeInDown"},[_vm._v(" 关于易橙"),_c('span',[_vm._v(" / ABOUT YICHENG")])]),_c('p',{staticClass:"textdong animate__bounceInLeft"},[_vm._v(" 易橙学堂是博财汇德信息技术(北京)有限公司旗下教育品牌，博财汇德是一家实力强劲的公司，它不仅获得了由北塔资本、维创资本、深度加速、大搜股的千万级投资，还屡次荣获包括：影响力在线教育品牌、行业标杆集团、公众信赖的技能培训品牌等荣誉。 易橙学堂是在线教育行业的领跑者，多年来坚持初心做良心企业，打造一站式课堂，专注于军队文职教育等领域，所有课程产品均由行业资深专家亲自主操研发，历经数年教学经验打磨，对答题技巧和面试技巧，也是有自己独特的方式和见解，受到业内的好评！ ")])]),_c('div',{staticClass:"about_right"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/img/about_top.jpg"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_title textdong animate__fadeInDown"},[_vm._v(" 发展历程"),_c('span',[_vm._v(" / DEVELOPMENT HISTORY")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_title textdong animate__fadeInDown"},[_vm._v(" 联系我们"),_c('span',[_vm._v(" / CONTACT US ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item_right"},[_c('p',[_vm._v("博财汇德信息技术（北京）有限公司")]),_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v(" 公司地址：北京市朝阳区农展南路5号9层901内-23 ")]),_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v("公司邮编：100020-23")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact"},[_c('div',{staticClass:"contact_min"})])
}]

export { render, staticRenderFns }